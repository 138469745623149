// // import React from "react";
// // import "../Style.css";
// // import Toast from "react-bootstrap/Toast";

// // import founder from "../img/Founders3.jpg";
// // import ceo from "../img/ceo.png";
// // import dir from "../img/dir.jpg";
// // const Aboutus = () => {
// //   return (
// //     <div>
// //       <section id="about" className="about section-bg">
// //         <div className="container" data-aos="fade-up">
// //           <div className="section-title">
// //             <h2>About</h2>
// //             <h3>
// //               Find Out More <span>About Us</span>
// //             </h3>
// //             <p>
// //               We have been in the mutual fund distribution business since 2000.
// //             </p>
// //           </div>
// //           <div className="row mt-5">
// //             <div
// //               className="col-lg-6 align-content-center justify-content-center text-center"
// //               data-aos="fade-right"
// //               data-aos-delay={100}
// //             >
// //               <img src={founder} className="img-fluid founder" alt />
// //             </div>
// //             <div
// //               className="inner-con col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center"
// //               data-aos="fade-up"
// //               data-aos-delay={100}
// //             >
// //               <Toast className="d-inline-block m-1">
// //                 <Toast.Header>
// //                   <img
// //                     src="holder.js/20x20?text=%20"
// //                     className="rounded me-2"
// //                     alt=""
// //                   />
// //                   <strong className="me-auto">Mr. Mohanraj</strong>
// //                   <small>11 mins ago</small>
// //                 </Toast.Header>
// //                 <Toast.Body>
// //                   Hello, I'm Mr. Mohanraj, the founder of MR Mutual Funds. With
// //                   over two decades of dedicated expertise in the dynamic realms
// //                   of mutual funds and investments, I've had the privilege of
// //                   transforming the financial lifestyles of numerous clients on
// //                   this incredible journey. Having spent more than 20 years in
// //                   the mutual funds and investment fields, I've witnessed the
// //                   transformative power of strategic financial planning. Join the
// //                   MR Mutual Funds family, where your financial aspirations are
// //                   not just goals; they are our shared commitment.
// //                 </Toast.Body>
// //               </Toast>
// //             </div>
// //           </div>
// //         </div>
// //       </section>
// //     </div>
// //   );
// // };

// // export default Aboutus;
// import React from "react";
// import "./Owner.css";
// import owner2 from "../img/found.jpeg";
// import owner1 from "../img/found1.jpeg";

// const Owner = () => {
//   return (
//     <div id="owner" data-aos="fade-in" className="mb-5">
//       <div className="Container-fluid " data-aos="fade-in">
//         <div className="section-title new" data-aos="fade-in">
//           <h2 data-aos="fade-in">ABOUT US</h2>
//           <h3 data-aos="fade-in">
//             CEO's <span> Corner</span>
//           </h3>
//         </div>
//         <div className="content-1" data-aos="fade-in">
//           <div class="row justify-content-center" data-aos="fade-in">
//             <div class=" col-12 col-xl-5 left-cont-one" data-aos="fade-in">
//               <img
//                 className="owner-img"
//                 src={owner1}
//                 alt="men"
//                 srcset=""
//                 data-aos="fade-in"
//               />
//             </div>
//             <div class=" col-12 col-xl-5 left-cont-two" data-aos="fade-in">
//               <h4 className="owner-head" data-aos="fade-in">
//                 Our Beloved Founder / R.Mohanraj, B.Com
//               </h4>
//               <p className="owner-para" data-aos="fade-in">
//                 "Hello, I'm Mr. Mohanraj, the founder of MR Mutual Funds. With
//                 over two decades of dedicated expertise in the dynamic realms of
//                 mutual funds and investments, I've had the privilege of
//                 transforming the financial lifestyles of numerous clients on
//                 this incredible journey. Having spent more than 20 years in the
//                 mutual funds and investment fields, I've witnessed the
//                 transformative power of strategic financial planning. Join the
//                 MR Mutual Funds family, where your financial aspirations are not
//                 just goals; they are our shared commitment.
//               </p>
//             </div>
//           </div>
//         </div>
//         <div className="content-2 mt-5" data-aos="fade-in">
//           <div class="row justify-content-center" data-aos="fade-in">
//             <div
//               class=" col-12 col-xl-5 right-cont-one order-lg-1 order-2"
//               data-aos="fade-in"
//             >
//               <h4 className="owner-head" data-aos="fade-in">
//                 Our Beloved Director/ Shrinidhi M R, BBA
//               </h4>
//               <p className="owner-para" data-aos="fade-in">
//                 {" "}
//                 Hello, I'm Shrinidhi M R, the Next-gen Director at MR Mutual
//                 Funds. With over 2 years of hands-on experience in the financial
//                 sector, I am dedicated to carrying forward the legacy of
//                 excellence established by Mr. Mohanraj. As an AMFI-registered
//                 Mutual Fund distributor, I bring a fresh perspective to our
//                 client-centric approach. Beyond this, I have completed a
//                 Certified Retirement Goal Planner certification and am actively
//                 pursuing my CFP (Certified Financial Planner) designation.{" "}
//               </p>
//             </div>
//             <div
//               class=" col-12 col-xl-5 right-cont-two order-lg-2 order-1"
//               data-aos="fade-in"
//             >
//               <img
//                 className="owner-img-2"
//                 src={owner2}
//                 alt="women"
//                 srcset=""
//                 data-aos="fade-in"
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Owner;

// import React from "react";
// import "./Owner.css";
// import owner2 from "../img/found.jpeg";
// import owner1 from "../img/found1.jpeg";

// const Owner = () => {
//   return (
//     <div id="owner" data-aos="fade-in" className="mb-5 mt-5">
//       <div className="Container-fluid " data-aos="fade-in">
//         <div className="section-title new" data-aos="fade-in">
//           <h2 data-aos="fade-in">ABOUT US</h2>
//           <h3 data-aos="fade-in">
//             CEO's <span> Corner</span>
//           </h3>
//         </div>
//         <div className="content-1" data-aos="fade-in">
//           <div class="row justify-content-center" data-aos="fade-in">
//             <div class=" col-12 col-xl-5 left-cont-one" data-aos="fade-in">
//               <img
//                 className="owner-img"
//                 src={owner1}
//                 alt="men"
//                 srcset=""
//                 data-aos="fade-in"
//               />
//             </div>
//             <div class=" col-12 col-xl-5 left-cont-two" data-aos="fade-in">
//               <h4 className="owner-head" data-aos="fade-in">
//                 Our Beloved Founder / R.Mohanraj, B.Com
//               </h4>
//               <p className="owner-para" data-aos="fade-in">
//                 "Hello, I'm Mr. Mohanraj, the founder of MR Mutual Funds. With
//                 over two decades of dedicated expertise in the dynamic realms of
//                 mutual funds and investments, I've had the privilege of
//                 transforming the financial lifestyles of numerous clients on
//                 this incredible journey. Having spent more than 20 years in the
//                 mutual funds and investment fields, I've witnessed the
//                 transformative power of strategic financial planning. Join the
//                 MR Mutual Funds family, where your financial aspirations are not
//                 just goals; they are our shared commitment.
//               </p>
//             </div>
//           </div>
//         </div>
//         <div className="content-2 mt-5" data-aos="fade-in">
//           <div class="row justify-content-center" data-aos="fade-in">
//             <div
//               class=" col-12 col-xl-5 right-cont-one order-lg-1 order-xl-1 order-2"
//               data-aos="fade-in"
//             >
//               <h4 className="owner-head" data-aos="fade-in">
//                 Our Beloved Director/ Shrinidhi M R, BBA
//               </h4>
//               <p className="owner-para" data-aos="fade-in">
//                 {" "}
//                 Hello, I'm Shrinidhi M R, the Next-gen Director at MR Mutual
//                 Funds. With over 2 years of hands-on experience in the financial
//                 sector, I am dedicated to carrying forward the legacy of
//                 excellence established by Mr. Mohanraj. As an AMFI-registered
//                 Mutual Fund distributor, I bring a fresh perspective to our
//                 client-centric approach. Beyond this, I have completed a
//                 Certified Retirement Goal Planner certification and am actively
//                 pursuing my CFP (Certified Financial Planner) designation.{" "}
//               </p>
//             </div>
//             <div
//               class=" col-12 col-xl-5 right-cont-two order-lg-2 order-xl-1 order-1"
//               data-aos="fade-in"
//             >
//               <img
//                 className="owner-img-2"
//                 src={owner2}
//                 alt="women"
//                 srcset=""
//                 data-aos="fade-in"
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Owner;
import React from "react";
import "./Owner.css";
import owner2 from "../img/found.jpeg";
import owner1 from "../img/found1.jpeg";

const Owner = () => {
  return (
    <div id="aboutus" data-aos="fade-in" className="mb-5 mt-5">
      <div className="Container-fluid " data-aos="fade-in">
        <div className="section-title new" data-aos="fade-in">
          <h2 data-aos="fade-in">ABOUT US</h2>
          <h3 data-aos="fade-in">
            CEO's <span> Corner</span>
          </h3>
        </div>
        <div className="content-1" data-aos="fade-in">
          <div className="row justify-content-center" data-aos="fade-in">
            <div className="col-12 col-xl-5 left-cont-one" data-aos="fade-in">
              <img
                className="owner-img"
                src={owner1}
                alt="men"
                srcSet=""
                data-aos="fade-in"
              />
            </div>
            <div className="col-12 col-xl-5 left-cont-two" data-aos="fade-in">
              <h4 className="owner-head" data-aos="fade-in">
                Our Beloved Founder - R.Mohanraj
              </h4>
              <p className="owner-para" data-aos="fade-in">
                I am Mr. Mohanraj, the Founder of M R Distribution Services. 
                With a wealth of experience spanning over twenty years in the ever-changing domains of mutual funds and investments, 
                I have had the honor of revolutionizing the financial well-being of countless clients throughout this remarkable voyage.
                 Throughout my extensive tenure in the mutual funds and investment sectors, I have observed the profound impact of 
                 strategic financial planning. Come and be a part of the M R Distribution Services community, where your financial
                  dreams are not merely objectives; they are our collective dedication.
              </p>
            </div>
          </div>
        </div>
        <div className="content-2 mt-5" data-aos="fade-in">
          <div className="row justify-content-center cont" data-aos="fade-in">
            <div
              className="col-12 col-xl-5 right-cont-one "
              data-aos="fade-in"
            >
              <h4 className="owner-head" data-aos="fade-in">
                Our Beloved Director - Shrinidhi M R
              </h4>
              <p className="owner-para" data-aos="fade-in">
              
                Hi I am Shrinidhi M R, Director of M R Distribution services.
                 Having accumulated more than 2 years of practical experience in the financial industry,
                  my commitment lies in upholding the distinguished legacy of excellence set by my father, 
                  Mr. Mohanraj. As a Financial Advisor, I introduce a novel viewpoint to our customer-focused methodology.
                   Furthermore, I have successfully obtained a certification as a Retirement Goal Planner from
                    American Academy of Financial Management and I am currently dedicated to achieving my 
                    designation as a Certified Financial Planner(CFP).
              </p>
            </div>
            <div className="col-12 col-xl-5 right-cont-two "
              data-aos="fade-in">
              <img
                className="owner-img-2"
                src={owner2}
                alt="women"
                srcSet=""
                data-aos="fade-in"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Owner;

